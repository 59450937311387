import React from "react";
import * as Company_Info from "../site-data/company-info";

const TopBar = () => {
  return (
    <div className="bg-primary d-flex justify-content-center top-bar">
      <p className=" text-center p-2 text_line ">Call us at {Company_Info.COMPANY_PHONE} today for a free consultation.</p>
    </div>
  );
};
export default TopBar;
