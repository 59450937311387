import { combineReducers } from "redux";
import { reducer as formReducer } from 'redux-form';

import menuIsOpenReducer from "./menuIsOpenReducer";
import routeReducer from "./routeReducer";
import searchMenuStateReducer from "./searchMenuStateReducer";
import searchTermReducer from "./searchTermReducer";
import videoPagination from "./videoPagination";
import vidoesReducer from "./vidoesReducer";
import modalIsOpenReducer from "./modalIsOpenReducer";
import selectedVideoReducer from "./selectedVideoReducer";

export default combineReducers({
  menuOpen: menuIsOpenReducer,
  route: routeReducer,
  term: searchTermReducer,
  searchOpen: searchMenuStateReducer,
  form: formReducer,
  videos: vidoesReducer,
  videoPage: videoPagination,
  modalOpen: modalIsOpenReducer,
  selectedVideo: selectedVideoReducer
});
