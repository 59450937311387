import React from "react";
import SearchBubble from "./SearchBubble";

const MainSearchBar = () => {
  return (
    <div className="d-flex justify-content-center" style={{ zIndex: "-1 !important" }}>
      <div className="container-fluid position-relative bg-secondary opacity-75" style={{ height: "150px" }}></div>
      <div className="container-lg opacity-100 position-absolute mt-5">
        <SearchBubble />
      </div>
    </div>
  );
};

export default MainSearchBar;
