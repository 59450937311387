import React, { useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import {v4 as uuid} from 'uuid';
import { Field, reduxForm } from "redux-form";
import variables from "../../../sass/core/_main.scss";
import FormMailer from "../../helpersScripts/formCreation";

class Form extends React.Component {
  constructor() {
    super();
    this.state = { message: "", styles: "" };
    this.captchaRef = React.createRef();
  }

  renderError = ({ error, touched }) => {
    if (touched && error) {
      return (
        <div className="p-1">
          <div className="fw-lighter" style={{ color: `${variables.focusColor}`, fontSize: ".85rem" }}>
            {error}
          </div>
        </div>
      );
    }
  };

  renderInput = ({ input, label, meta, type, inputType, required }) => {
    const fieldType = () => {
      if (inputType === "input") {

        return (
            <React.Fragment>
                <div className={"contact-form__control"}>
                    <input
                        {...input}
                        autoComplete="off"
                        placeholder=" "
                        className="form form-control input-field"
                        type={type}
                        required={required ?? false}

                    />
                    <label className={"input-label"} htmlFor={input["name"]}>{label}</label>
                {this.renderError(meta)}
                </div>
            </React.Fragment>
        );
      }

        return (
            <React.Fragment>
                <div className={"contact-form__control"}>
                      <textarea
                          {...input}
                          autoComplete="off"
                          placeholder=" "
                          className="form form-control input-field"
                          required={required ?? false}
                          rows={4}
                          style={{
                              fontFamily: "Montserrat,sans-serif",
                              fontWeight: "200",
                              width: "100%",
                              borderRadius: "0px",
                              padding: ".85rem",
                          }}
                      />
                    <label className={"input-label"} htmlFor={input["name"]}>{label}</label>
                    {this.renderError(meta)}
                </div>
            </React.Fragment>
      );
    };

    return <div className="pt-2">{fieldType()}</div>;
  };

  onSubmit = async (formValues) => {
    // const captchaChecked = this.captchaRef.current.getValue();


    // if (!captchaChecked) return;

    formValues.email =  formValues.email ?? "";
    const response = await new FormMailer(this.props.formInfo, formValues).sendContactForm();

    this.setState({ message: response.message });
    this.setState({ styles: `form-message-block form-${response.status === 200 ? "success" : "failure"}` });

    setTimeout(() => {
      this.setState({ styles: `form-message-block form-message-show form-${response.status === 200 ? "success" : "failure"}` });
    }, 100);

    setTimeout(() => {
      this.setState({ styles: "form-message-block" });
      setTimeout(() => {
        this.setState({ message: "" });
        this.setState({ styles: "" });
      }, 500);
    }, 4000);
  };

  render() {
    return (
      <form name={this.props.formInfo.title} onSubmit={this.props.handleSubmit(this.onSubmit)} className="mt-4">
        {this.props.formInfo.fields.map((field, index) => {

          return (

                <React.Fragment key={field.name + index}>
                    <Field
                      key={field.name + index}
                      name={field.name}
                      label={field.label}
                      component={this.renderInput}
                      type={field.type}
                      inputType={field.inputType}
                      normalize={field.normalizeFn}
                      validate={field.validationFn}
                      required={field.required}
                    />
                </React.Fragment>

          );
        })}
        <div className="position-relative  text-center">
          <div className={`position-absolute p-2 w-100 py-3 form-message text-white ${this.state.styles}`}>{this.state.message}</div>
        </div>
        <div className="d-flex justify-content-center py-4">
          {/*<ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} ref={this.captchaRef} />*/}
        </div>
        <button
          className="btn btn-primary w-100 rounded-0 shadow p-3 "
          style={{
            backgroundColor: variables.primaryAccentColor,
            fontWeight: "200",
            zIndex: "30",
          }}
        >
          Submit
        </button>
      </form>
    );
  }
}

// const validate = (values) => {
//   const errors = {};

//   console.log("vals", Form);
//   if (!values.firstName) {
//     errors.firstName = "Required";
//   } else if (values.firstName.length > 15) {
//     errors.firstName = "Must be 20 characters or less";
//   }

//   if (!values.lastName) {
//     errors.lastName = "Required";
//   } else if (values.lastName.length > 15) {
//     errors.lastName = "Must be 20 characters or less";
//   }
//   if (!values.email) {
//     errors.email = "Required";
//   } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
//     errors.email = "Invalid email address";
//   }
//   if (!values.phone) {
//     errors.phone = "Required";
//   } else if (values.phone.split("-").join("").length < 9 || values.phone.split("-").join("").length > 13) {
//     errors.phone = "Must be a phone number";
//   }
//   if (!values.message) {
//   }
//   return errors;
// };

export default reduxForm({})(Form);
