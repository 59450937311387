export const clientProcess = {
  slug: "client-process",
  image: "/img/meeting.jpg",
  imageYAlign: 60,
  imgAlt: "Two people discussing case at table",
  title: "Our Client Process",
  link: "/client-process",
  formDistanceFromTop: "300px",
  description:
    "Every case on personal injury and the circumstances that surround them are always different. One fact about them is always true. The time directly after the incident which caused the injury can be stressful and confusing...",
  form: "DiscussYourCase",
  formTopPosition: true,
  showForm: true,
  contentTitle: "",
  content: [
    <div className="row ms-3 mt-5 mb-5 ">
      <p className="fw-light mt-2">
        Every case on personal injury and the circumstances that surround them are always different. One fact about them is always true. The
        time directly after the incident which caused the injury can be stressful and confusing. Know Negem & Worthington is the right
        attorneys which can provide the support you need during this time of stress and confusion.
      </p>
      <p className="fw-light mb-5">
        We are passionate advocates that work tirelessly on your behalf to get the restitution you deserve. Throughout the case, our team
        will be there every step of the way. We will help you collect damages for medical bills, long-term care, repair or replacement of
        property, and other compensation you need to put your life back together.
      </p>

      <h4 className="fw-light mb-2 mt-5">Your Initial Meeting</h4>
      <p className="fw-light mt-2">
        Our first meeting will focus on answering the question that worry you the most. How will I pay my bills? How will I afford
        treatment? How will I pay my medical expenses? What will my insurance cover? What about all the time I will be away from work?
      </p>
      <p className="fw-light">
        When an unexpected event happens, there are many questions that can become overwhelming. You can feel comfortable talking about the
        details of your case with us because everything is private and confidential. You can count on us to explain all the options you have
        and how you will be involved from our first meeting throughout the entire case.
      </p>

      <h4 className="fw-light mb-2 mt-5">Constructing A Team For You</h4>
      <p className="fw-light mt-2">
        Allowing you to focus on recovery is incredibly important to us. Building a case and assembling the correct team that removes the
        stress of your case, allows you a better chance at recovering. Our team will assemble and collect the needed records, documentation,
        and other data that will help to strengthen you claim while still allowing your recovery.
      </p>

      <h4 className="fw-light mb-2 mt-5">Collecting Medical Data</h4>
      <p className="fw-light mt-2">
        Being in an accident which caused personal injury can derail your everyday life. It can be difficult for continue taking care of the
        daily tasks you one was able to complete.
      </p>
      <p className="fw-light">
        Lessening that burden is important to us. Your team will collect all the necessary medical and hospital records, post-incident
        reports, and medical bills. We will help you obtain payment for your medical bills through your insurance coverage or though someone
        else’s car insurance until reimbursement and compensation are received.
      </p>
      <p className="fw-light">
        We will handle assembling your case so you can concentrate on recovery because your health and well-being is most important.
      </p>

      <h4 className="fw-light mb-2 mt-5">Negotiating Your Case</h4>
      <p className="fw-light mt-2">
        Large insurance companies can be intimidating for you to deal with. We realize how stressful it is, so we keep you informed on your
        cases process while speaking to the insurance companies for you. After all the information has been gathered caused by the negligent
        party including your medical costs, property loss, wage loss, and any other expenses, we will present a complete package to the
        insurance company.
      </p>
      <p className="fw-light">
        After the insurance company sends a settlement offer, your team will review it carefully with you and together we will decide on the
        appropriate next steps should be. Your team will provide our best professional legal advice but, in the end, you make the final
        decision.
      </p>

      <h4 className="fw-light mb-2 mt-5">Filing A Lawsuit</h4>
      <p className="fw-light mt-2">
        Receiving far and just compensation for you or your loved one’s injuries is the end goal of every personal injury case. The
        insurance company’s settlement offer must be fair and if together we decide that it is not fair to you and the insurance company is
        not willing to negotiate any further, we will file a lawsuit on your behalf.
      </p>
      <p className="fw-light">
        An Arbitration or mediation might be an option your attorney might recommend before taking the time and expense of elevating your
        case to trial. But if this does not give the desired result, your attorney will prepare your case for a trial in front of a jury.
        Rest assured, the team at Negem & Worthington will buffer all the unnecessary anxiety and worry that might be caused by going to
        trial. We will prepare you for what to expect and you will feel confident knowing the case presented to the jury by your dedicated
        team is solid.
      </p>
      <p className="fw-light">Together we will focus on one thing, a fair and just outcome for you or your loved ones personal injury.</p>
    </div>,
  ],
};
