import React, {useEffect} from "react";
import { Link } from "react-router-dom";
import videoModal from "./videoModal";
import {connect,useDispatch} from "react-redux";
import {fetchVideos, fetchSelectedVideo, fetchModalIsOpen} from "../../actions";


export const VideoTiles = ({ tiles, fetchSelectedVideo, selectedVideo, modalOpen, fetchModalIsOpen }) => {


    const onVideoClick = (video) => {

       selectedVideo = video;

        fetchModalIsOpen(!modalOpen);
        fetchSelectedVideo(video)


    }




  return (
    <div >
      <div className="row pb-5">
        {tiles
          ? tiles.map((tile, index) => {
              return (
                // <Link to={tile.link}  className="" style={{ textDecoration: "none" }}>
                  <div key={index}  className="tile mt-4 shadow-sm" style={{ background: `url('${tile.image}') center center/cover`, height: "360px" }} onClick={onVideoClick.bind(this, tile.link)}>
                    <div
                      className="position-realtive "
                      style={{
                        background: "linear-gradient(0deg, rgba(0,0,0,0.49625787815126055) 10%, rgba(0,0,0,0) 40%)",
                        height: "360px",
                      }}
                    ></div>
                    <div className="p-3 text-white position-relative fw-light" style={{ transform: "translateY(-100%)" }}>
                      <h4 className="fw-lighter ">{tile.title}</h4>
                      <div className="tile-line"></div>
                    </div>
                  </div>
                // </Link>
              );
            })
          : ""}
      </div>
    </div>
  );
};

const mapStateToProps = state => {
    return {
        selectedVideo: state.selectedVideo,
        modalOpen: state.modalOpen,
    }
}
export default connect(mapStateToProps, { fetchVideos,fetchSelectedVideo, fetchModalIsOpen })(VideoTiles);
