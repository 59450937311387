import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { fetchMenuIsOpen } from "../../actions";
import { menuReaction } from "../helpersScripts/menuReact";
import * as Company_Info from "../site-data/company-info";

const MainLogo = ({ menuOpen, fetchMenuIsOpen }) => {
  const ref = useRef();

  useEffect(() => {
    document.body.addEventListener(
      "click",
      (event) => {
        menuReaction(event, fetchMenuIsOpen, ref, menuOpen);
      },
      {
        capture: true,
      }
    );

    return () => {
      document.body.removeEventListener(
        "click",
        (event) => {
          menuReaction(event, fetchMenuIsOpen, ref, menuOpen);
        },
        {
          capture: true,
        }
      );
    };
  }, []);

  return (
    <div className="p-3 main-logo img-fluid">
      <Link to={"/"} ref={ref}>
        <img
          className="main-logo"
          src={Company_Info.COMPANY_LOGO}
          alt={Company_Info.COMPANY_NAME}
        />
      </Link>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    menuOpen: state.menuOpen,
  };
};

export default connect(mapStateToProps, { fetchMenuIsOpen })(MainLogo);
