export const fetchMenuIsOpen = (open) => {
  return {
    type: "FETCH_MENU_IS_OPEN",
    payload: open,
  };
};

export const fetchRoute = (route) => {
  return {
    type: "FETCH_ROUTE",
    payload: route,
  };
};

export const fetchSearchTerm = (term) => {
  return {
    type: "FETCH_SEARCH_TERM",
    payload: term,
  };
};

export const fetchSearchMenuState = (state) => {
  return {
    type: "FETCH_SEARCH_MENU_STATE",
    payload: state,
  };
};

export const fetchVideos = (state) => {
  return {
    type: "FETCH_VIDEOS_STATE",
    payload: state,
  };
};

export const fetchVideoPage = (state) => {
  return {
    type: "FETCH_SEARCH_VIDEO_PAGE",
    payload: state,
  };
};

export const fetchModalIsOpen = (state) => {
  return {
    type: "FETCH_MODAL_IS_OPEN",
    payload: state,
  };
}

export const fetchSelectedVideo = (video) => {
  return {
    type: "FETCH_SELECTED_VIDEO",
    payload: video,
  };
}