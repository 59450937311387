import React from "react";
import { COMPANY_PHONE } from "../site-data/company-info";

const PhoneIcon = () => {
  return (
    <div className="-m-2">
      <a href={`tel:${COMPANY_PHONE}`}>
        <img height="35" src="/img/phone.svg" alt="Click here to call us now" />
      </a>
    </div>
  );
};

export default PhoneIcon;
