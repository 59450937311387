import React from "react";
import { useLocation } from "react-router-dom";
import PageDataController from "../site-data/components/PageDataController";
import FormContainer from "../components/ContactForms/FormContainer";

import FormSelection from "../site-data/components/Forms";

const About = () => {
  const location = useLocation();
  const path = location.pathname.split("/");
  let slug = "";
  while (slug === "") {
    slug = path.pop();
  }

  const [data] = new PageDataController(slug).returnTitlePage();
  const form = new FormSelection(data.form).returnSelection();

  if (data.title) {
    document.title = "N&W | " + data.title;
  }
  return (
    <div className="bg-lightGrey" id="overview">
      <div className="" style={{ background: `url(${data.image})  40% ${data.imageYAlign}% /cover` }}>
        <div>
          <div
            className="container-xl text-white ps-4 ps-xl-5 ps-xxl-0 heroText section-content-header"
            style={{
              paddingBottom: "1rem",
              fontFamily: "PT Serif Caption,serif",
              zIndex: "1",
              textShadow: "3px 3px 12px rgba(0,0,0,0.3)",
            }}
          >
            {data.title}
          </div>
        </div>
      </div>
      <div className="container-xl bg-lightGrey pb-lg-5" style={{ zIndex: "9999" }}>
        <div className="row" style={{ minHeight: "400px" }}>
          <div className={data.showForm ? `col-lg-7 col-xl-8 pt-5 ps-sm-5 px-4 ` : "px-sm-5  pt-5"}>
            {data.contentTitle ? <p className="fw-light h3 pb-3">{data.contentTitle}</p> : ""}

            {data.content.map((el, i) => (
              <div key={i} className="fw-light pb-3">
                {el}
              </div>
            ))}
          </div>
          {data.showForm ? (
            <div className="col-lg-5 col-xl-4  order-first order-lg-last position-relative">
              <div className={` mt-3 ${data.formTopPosition ? "title-form sticky-top secondary__form-container" : ""}  `}>
                <FormContainer form={form} />
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default About;
