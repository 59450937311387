import { ADDRESS1, ADDRESS_SUITE, ADDRESS_CITY, ADDRESS_STATE, ADDRESS_ZIP, COMPANY_PHONE, COMPANY_FAX } from "../../../company-info";

export const contactUs = {
  slug: "contact-us",
  image: "/img/contactus.jpg",
  imageYAlign: 0,
  imgAlt: "Individual holding a phone",
  title: "Contact Us",
  link: "/contact-us",
  description:
    " Don’t ever think you have to shoulder the financial burden of a crash that was not caused by you but by someone else’s negligence. Let Negem & Worthington help you receive the compensation you deserve so you can heal from the trauma cause by the accident ...",
  form: "DiscussYourCase",
  formTopPosition: false,
  showForm: true,
  contentTitle: "",
  content: [
    <div>
      Don’t ever think you have to shoulder the financial burden of a crash that was not caused by you but by someone else’s negligence. Let
      Negem & Worthington help you receive the compensation you deserve so you can heal from the trauma cause by the accident.
    </div>,
    <div>
      From minor accidents to severe collisions, our attorneys can help you navigate the intimidating field of insurance claims to make sure
      you recover the compensation you deserve. Contact us today to discuss your unique situation at no cost.
    </div>,
    <div>
      <div className="row ms-3 mt-5 ">
        <div className="bg-white px-4 pt-3 pb-2 col-auto">
          <h4 className="fw-light mt-4 mb-3 text-primary ps-3">Our Tyler Office</h4>
          <p className="px-3 pb-3 lh-lg">
            {ADDRESS1} {ADDRESS_SUITE}
            <br />
            {ADDRESS_CITY}, {ADDRESS_STATE} {ADDRESS_ZIP}
            <br />
            <strong>Phone:</strong> {COMPANY_PHONE}
            <br />
            <strong>Fax:</strong> {COMPANY_FAX}
          </p>
        </div>
      </div>
    </div>,
  ],
};
