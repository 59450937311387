import { titlePages } from "./titlePages";
import { contentPages } from "./contentPages";
import { FlipperContent } from "./flipper/index.js";

const basicForm = "DiscussYourCase";

export default class PageDataController {
  selector;
  refinedSelector;

  constructor(selector = null) {
    this.selector = selector;
    if (!this.selector) return;
    this.redefineType();
  }

  returnTitlePage() {
    return this.findPage(titlePages());
  }

  returnContentPage(page) {
    this.redefineType();
    return this.findPage(contentPages(this.refinedSelector, page));
  }

  returnContentLinks(type) {
    return contentPages(type).map((el) => {
      return {
        link: el.link,
        title: el.title,
      };
    });
  }

  sliderData(type) {
    return contentPages(type).map((el) => {
      return {
        title: el.title,
        description: el.description,
        link: el.link,
        image: el.image,
      };
    });
  }

  tileData(type) {
    return this.sliderData(this.redefineType(type)).map((el) => {
      return {
        title: el.title,
        link: el.link,
        image: el.image,
      };
    });
  }
  flipperData() {
    return FlipperContent();
  }

  findPage(pages) {
    if (!pages.length) {
      if (!pages.form) pages.form = basicForm;

      return pages;
    }

    // console.log(this.selector, pages);
    for (let i = 0; i < pages.length; i++) {
      if (this.selector === pages[i].slug) return [pages[i], this.refinedSelector];
    }
  }

  redefineType = (type = null) => {
    let selector = type !== null ? type.split("-") : this.selector.split("-");

    if (selector.length > 1) {
      return (this.refinedSelector = selector.map((el, i) => (i !== 0 ? el[0].toUpperCase() + el.slice(1) : el)).join(""));
    }
    return (this.refinedSelector = selector[0].toLowerCase());
  };

  returnSiteMapLinks = () => {
    let allPages = [...contentPages(), ...titlePages()];
    if (allPages === undefined) return [];
    return allPages.map((page) => {
      return {
        title: page.title,
        link: page.link,
      };
    });
  };

  returnSearch = (term) => {
    let pages = contentPages();
    let titleP = titlePages();
    let allPages = [...pages, ...titleP];

    term.split(" ").forEach((searchTerm) => {
      allPages = allPages.filter((page) => {
        // console.log(page);
        if (JSON.stringify(page).match(searchTerm)) {
          return page;
        }
        return null;
      });
    });

    return allPages;
    // return contentPages().filter((el) => (JSON.stringify(el).match(term) ? el : null));
  };
}
