import React from "react";
import { Link } from "react-router-dom";

export const Tiles = ({ tiles }) => {
  return (
    <div className="pt-lg-5">
      <div className="row">
        {tiles
          ? tiles.map((tile, index) => {
              return (
                <Link to={tile.link} key={index} className=" col-xl-3 col-lg-4 col-md-6" style={{ textDecoration: "none" }}>
                  <div className="tile mt-4 shadow-sm" style={{ background: `url('${tile.image}') center center/cover`, height: "360px" }}>
                    <div
                      className="position-realtive "
                      style={{
                        background: "linear-gradient(0deg, rgba(0,0,0,0.49625787815126055) 10%, rgba(0,0,0,0) 40%)",
                        height: "360px",
                      }}
                    ></div>
                    <div className="p-3 text-white position-relative fw-light" style={{ transform: "translateY(-100%)" }}>
                      <h4 className="fw-lighter ">{tile.title}</h4>
                      <div className="tile-line"></div>
                    </div>
                  </div>
                </Link>
              );
            })
          : ""}
      </div>
    </div>
  );
};

export default Tiles;
