import React from "react";
import NavLinks from "./NavLinks";
import SearchBubble from "./SearchBubble";
import { topLinksList, bottomLinksList } from "../site-data/components/MenuNavLinks";
import SocialMediaLinks from "./SocialMediaLInks";

const MenuNavLinks = ({ menuOpen }) => {
  return (
    <div>
      <div className="container">
        <NavLinks links={topLinksList} styling={"nav-top-text"} textAlignStyle="text-center text-lg-start" />
      </div>
      <div className="container mt-5">
        <NavLinks links={bottomLinksList} styling={"nav-bottom-text"} textAlignStyle="text-center text-lg-start" />
      </div>
      <div className="m-3 mt-5 d-flex justify-content-center justify-content-lg-start ">
        <div className="row " style={{ maxWidth: "350px" }}>
          <div className="">
            <SearchBubble />
          </div>
        </div>
      </div>
      <div className="m-3 mt-5 d-flex justify-content-center justify-content-lg-start ">
        <div className="row ">
          <div className="d-flex justify-content-center">
            <SocialMediaLinks />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuNavLinks;
