import React from "react";

const OutlinedCard = ({ children, minHeight = null, column = null }) => {
  return (
    <div className={`${column} p-2  `} style={{ minHeight: minHeight }}>
      <div
        className="bg-lightGrey pt-5 ps-4 pe-4 pb-5 text-secondary border-borderLightGrey shadow w-100"
        style={{ opacity: ".9", border: "12px solid", minHeight }}
      >
        {children}
      </div>
    </div>
  );
};

export default OutlinedCard;
