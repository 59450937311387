import React from "react";
import Form from "./Form";
import {v4 as uuid} from 'uuid';

import variables from "../../../sass/core/_main.scss";

const FormContainer = ({ form }) => {
  return (
    <div
      style={{
        // padding: "3.75rem 2.5rem",
        backgroundColor: variables.primaryColor,
        padding: "1rem 1.75rem",
        border: `10px solid ${variables.primaryAccentColor}`,
      }}
    >
      <div className="text-white h3 text-center" style={{ fontFamily: "PT Serif Caption,serif" }}>
        {form.title}
      </div>
      <div
        className="text-white text-center "
        style={{
          fontSize: "1rem",
          lineHeight: "1.2",
          fontWeight: 200,
        }}
      >
        {form.tagLine}
      </div>
      <Form formInfo={form} form={form.title} key={uuid()} />
      <div style={{ color: "white", fontWeight: 100, fontSize: ".85rem" }} className="mt-3 text-center">
        {form.reCAPTCHALine}
      </div>
    </div>
  );
};

export default FormContainer;
