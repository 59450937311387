import { attorneys } from "./pages/Attorneys";
import { practiceAreas } from "./pages/PracticeAreas";
import { about } from "./pages/About";
import { contactUs } from "./pages/ContactUs";
import { directions } from "./pages/Directions";
import { fourOfour } from "./pages/FourOFour";
import { clientProcess } from "./pages/ClientProcess";

const pages = [attorneys, practiceAreas, about, contactUs, directions, fourOfour, clientProcess];

export const titlePages = () => {
  return pages;
};
