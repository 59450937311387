import React from "react";
import * as Company_Info from "../site-data/company-info";

const CallUs = () => {
  return (
    <div className="d-flex">
      <p className={`nav-call-text fw-lighter nav-element `}>Call us at </p>
      <div className="nav-phone">
        <h4> {" " + Company_Info.COMPANY_PHONE}</h4>
      </div>
    </div>
  );
};

export default CallUs;
