// eslint-disable-next-line import/no-anonymous-default-export
export default (open = false, action) => {

  switch (action.type) {
    case "FETCH_MODAL_IS_OPEN":
      return action.payload;
    default:
      return open;
  }
};
