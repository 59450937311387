const fipperPages = [
  {
    title: "Experience That Matters",
    content:
      "For more than 40 years, the law firm of Negem & Worthington has represented individuals and families in Tyler and across East Texas in a range of personal injury and wrongful death claims, including automobile and commercial vehicle accidents, oilfield injuries and incidents of toxic exposure. Our attorneys have the experience to handle claims involving serious harm such as spinal cord and traumatic brain injury. We also represent clients in certain commercial litigation matters.",
  },
  {
    title: "Putting Our Knowledge to Work for You",
    content:
      "Losing a loved one can be very difficult. On another front, recovering from a traumatic injury can be a painful and grueling process. Our attorneys have handled thousands of claims. We are not afraid to stand up against insurance companies or large corporations to ensure that you receive compensation for either your loss of a loved one or traumatic injury. Each of our lawyers grew up in Texas, giving us an in-depth understanding of the unique issues that are important to our neighbors who live and work in this region.",
  },
  {
    title: "Representing Personal Injury Claims",
    content:
      "Our successful results include numerous multimillion-dollar verdicts and settlements. We use expert witnesses to prove negligence of the defendant. If you have suffered any type of injury due to the negligence of reckless actions of another person or company, the attorneys of Negem & Worthington are here to assist you. We are dedicated to helping our clients overcome devastating physical, emotional, and financial losses. Whether your case ends in negotiations or at trial, we will help you obtain the maximum recovery you are entitled to.",
  },
  {
    title: "Helping Businesses Get Justice",
    content:
      "The attorneys at Negem & Worthington work with businesses to settle or litigate a variety of disputes stemming from contracts and insurance policies to patent infringement claims. We also represent clients in arbitration where one or more neutral parties make a ruling based on evidence. Depending on the terms of the contract involved and if your arbitration claim is successful, your attorneys’ fees may be reimbursed by the other party. Our attorneys can also help with patent infringement claims, we use knowledge to establish that another company or individual unlawfully used your patented product or method and then pursue an injunction to stop them as well and demand damages to compensate you.",
  },
];
export const FlipperContent = () => {
  return fipperPages;
};
