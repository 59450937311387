export const fourOfour = {
  slug: "page-not-found",
  image: "/img/guy-phone.jpg",
  imageYAlign: 0,
  imgAlt: "Individual holding a phone",
  title: "We cannot seem to find the page you are looking for.",
  link: "/page-not-found",
  form: "DiscussYourCase",
  formTopPosition: true,
  showForm: false,
  contentTitle: "",
};
