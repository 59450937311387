import React from "react";
import OutlinedCard from "./OutlinedCard";

export const InjuryTypes = ({ title, data }) => {
  return (
    <React.Fragment>
      <div className="container-fluid p-md-5 pt-5" style={{ background: `url('/img/tyler-golden-vinyette.jpg') center center/cover` }}>
        <div className="text-white container-lg">
          <h2 className="fw-lighter">{title}</h2>
          <div className="row w-full d-flex flex-wrap">
            {data.map((el, i) => {
              return (
                <OutlinedCard key={i} minHeight={{ minHeight: "350px" }} column={"col-sm-6 col-md-4 d-flex"}>
                  <div className="h4 fw-light">{el.title}</div>
                  <div className="fw-light">{el.content}</div>
                </OutlinedCard>
              );
            })}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default InjuryTypes;
