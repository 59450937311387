import paralysisTile from "../../injuryLitigationTypes/tiles/paralysis";
import spinalInjuriesTile from "../../injuryLitigationTypes/tiles/spinalInjuries";

const autoAccidents = {
  slug: "auto-accidents",
  link: "/practice-areas/auto-accidents",
  image: "/img/PASlider/autoAccident.jpg",
  imgAlt: "image of broken windshield of sedan",
  imageYAlign: "70",
  title: "Auto Accidents",
  infoTitle: "Injury Types",
  infoTiles: [paralysisTile, spinalInjuriesTile],
  showSlider: true,
  showProcess: true,
  showForm: true,
  description:
    "Serious injuries caused by the negligence of how other indeviduals are driving happens every day. Negem & Worthington can help.",
  contentTitle: "",
  content: (
    <div className="row overview ">
      <div className="h2 fw-light mb-4 ">Auto Accident Law Firm</div>
      <div className="h4 fw-light mb-4 mt-2">Best Auto Accident Lawyers in East Texas</div>
      <p className="fw-light mt-2">
        Every day there are thousands of auto accidents and hundreds of individuals succumb to the injuries caused by those accidents. The
        resulting injuries can prevent you from living the life you used to for a day, month, or even years after the collision. This
        deferment from normal life can lead to frustration, fear, and uncertainty about the future. You might feel you have more questions
        than answers. The attorneys at Negem & Worthington can help you get those questions answered.
      </p>
      <p className="fw-light">
        We understand that after a serious accident you need to concentrate on your recovery or the recovery of a loved one. Unfortunately,
        the minimum liability insurance required by Texas law is usually inadequate to cover the damages caused by catastrophic accidents.
        While receiving compensation for the harm you have suffered is of utmost importance, the stress of a lawsuit can be taxing. With
        this in mind, we will take over the case and use our collective skill and experience to uncover every contributing cause of your
        accident and identify all potentially liable parties. We can also assist you in finding medical specialists and other care
        providers, and petition that they wait for payment until your case is resolved through settlement or trial.
      </p>

      <h4 className="fw-light mt-5 ">What Are The Typical Causes of Auto Accidents?</h4>
      <p className="fw-light mt-3">
        Drivers must exercise reasonable care on Texas’ roads and highways. However, most auto accidents stem from failure to abide by
        traffic laws or negligent and/or reckless driving, such as:
      </p>
      <div className="ms-md-5 mt-2 fw-light">
        <ul className="mb-4 list">
          <li>Driving too fast or too slowly</li>
          <li>Driving under the influence of drugs or alcohol</li>
          <li>Failing to signal while turning</li>
          <li>Disregarding weather or traffic conditions</li>
          <li>Disobeying traffic signs or signals</li>
          <li>Failing to drive on the right side of the road</li>
        </ul>
      </div>
      <p className="fw-light">
        Also, drivers too often attempt to multi-task behind the wheel, engaging in such dangerous activities as talking and texting on cell
        phones. However, factors other than poor judgment behind the wheel contribute to the high number of car and truck collisions; in
        some cases, extenuating circumstances may have led to your personal injury or accident, triggering third party liability.
      </p>

      <h4 className="fw-light mt-5 ">Are There Additional Causes of Accidents and Third Party Liability?</h4>
      <p className="fw-light mt-3">
        Accidents are sometimes caused by factors unrelated to any particular driver’s behavior, such as a vehicle malfunction, faulty
        mechanical repairs, or poorly maintained roads. In any of these circumstances, a third party may be liable for your injuries. For
        example, if a vehicle or vehicle component is proven to be dangerously defective, the manufacturer may be responsible for any harm
        caused by that defect. Similarly, if a mechanic fails to properly repair a vehicle, and that failure causes an accident, you may be
        entitled to recover from the mechanic and/or repair shop. If your accident was caused by deficient roadway maintenance, the state
        may be liable for any injuries that result. Inadequate roadway maintenance may include:
      </p>
      <div className="ms-md-5 mt-2 fw-light">
        <ul className="mb-4 list">
          <li>Malfunctioning traffic signals</li>
          <li>Damage or missing traffic signs</li>
          <li>Improper striping on passing lanes</li>
          <li>Problems with the roadway that obstruct a driver’s vision</li>
          <li>Poorly placed trees and utility poles</li>
        </ul>
      </div>

      <h4 className="fw-light mt-5 ">What happens if your involved in an accident with a drunk driver?</h4>
      <p className="fw-light mt-3">
        The state may prosecute a criminal case against a person charged with driving under the influence (DUI); however, if you or a loved
        one was injured because of another’s decision to drive drunk, you must seek compensation in civil court in order to recover for your
        personal injury or wrongful death. Due to diminished sensory perception and motor skills, drunk drivers rarely take evasive measures
        to mitigate the severity of collisions, leading to severe, high-impact accidents and especially serious injuries. If your accident
        was caused by a drunk driver, our experienced and aggressive auto accident lawyers will fight to see you fairly compensated for the
        losses you have suffered. In some cases, it may also be possible to pursue recovery against parties that provided alcohol to the
        drunk driver; under Texas law, liability may attach to liquor stores, bars, or restaurants who serve alcohol to persons who are
        obviously intoxicated.
      </p>
    </div>
  ),
};

export default autoAccidents;
