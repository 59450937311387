import React from "react";

import NavLinks from "../components/NavLinks";

const FooterNavLinks = ({ headerLink, bottomLinks }) => {
  return (
    <React.Fragment>
      <div className="mt-5">
        <NavLinks links={headerLink} styling="footer-links" textAlignStyle="text-start" />
      </div>
      <div className="footer-line" />
      <div className="mt-3">
        <NavLinks links={bottomLinks} styling="footer-links" textAlignStyle="text-start" />
      </div>
    </React.Fragment>
  );
};

export default FooterNavLinks;
