import React from "react";
import FormContainer from "./ContactForms/FormContainer";

export const AttorneyPageContent = ({ data, form, scrollRefs }) => {
  return (
    <React.Fragment>
      <div className="container-xl bg-lightGrey" style={{ zIndex: "9999" }}>
        <div className="row">
          <div className="col-lg-7 col-xl-8 pt-5 p-md-5">
            {data.content.map((content, i) => {
              return (
                <div key={i} ref={(el) => (scrollRefs.current[i] = el)}>
                  {data.content[i]}
                </div>
              );
            })}
          </div>

          <div className="col-lg-5 col-xl-4 order-first order-lg-last">
            <div className="sticky-top wm-25 mt-3 mb-3" style={{ top: "215px" }}>
              <FormContainer form={form} />
            </div>
          </div>
        </div>
      </div>
      {/* Section */}

      {/* <div ref={(el) => (scrollRefs.current[1] = el)} className="pb-5">
        <InjuryTypes title={data.infoTitle} data={data.infoTiles} />
      </div> */}
    </React.Fragment>
  );
};

export default AttorneyPageContent;
