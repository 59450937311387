import paralysisTile from "../../injuryLitigationTypes/tiles/paralysis";
import severBurnsTile from "../../injuryLitigationTypes/tiles/severeBurns";
import spinalInjuriesTile from "../../injuryLitigationTypes/tiles/spinalInjuries";

const personalInjury = {
  slug: "personal-injury",
  link: "/practice-areas/personal-injury",
  image: "/img/PASlider/personalInjury.jpg",
  imgAlt: "man being treated for hand injury",
  imageYAlign: "50",
  title: "Personal Injury",
  description: "Unexpected hazards can lead to expensive medical bills. Our experienced attorneys can help you receive fair compensation.",
  infoTitle: "Injury Types",
  infoTiles: [paralysisTile, spinalInjuriesTile, severBurnsTile],
  showSlider: true,
  showProcess: true,
  showForm: true,
  contentTitle: "",
  content: (
    <div className="row overview ">
      <div className="h2 fw-light mb-4 ">Personal Injury Law Firm</div>
      <div className="h4 fw-light mb-4 mt-2">Best Personal Injury Lawyers in East Texas</div>
      <p className="fw-light mt-2">
        As you take a walk in your own neighborhood, a dog chases you down resulting in a vicious bite. You’re at a grocery store walking
        down an aisle and find yourself on the floor after slipping on spilled liquid. There are so many times you might find yourself
        acquiring an injury that is a result of the negligence of another party. Other injuries might range from medical malpractice,
        nursing home abuse and airplane accidents. Are your injuries, resulting medical bills, and lost time from work your responsibility?
        In personal injury cases, it is essential to thoroughly investigate the accident, preserve relevant evidence, and enable a qualified
        physician to fully evaluate your injuries. With more than 50 years of collective legal experience, our Texas personal injury
        attorneys have the knowledge, experience, and advocacy skills to effectively represent your interests.
      </p>

      <h4 className="fw-light mt-5 ">In the Event of an Injury Under These Conditions, What Should I do?</h4>

      <p className="fw-light mt-3">
        Most victims don’t have the knowledge required to bring a case against a property owner or negligent party. You need to prove your
        case and know how to defend yourself against the incoming allegations that contributed to your accident. Here at Negem &
        Worthington, our attorneys have the experience to guide you through the entire filing and recovery process. We handle the paperwork
        and the deadlines so you can focus on healing and putting your life back together. Personal injury cases can take from a few months
        to years to be resolved depending on whether the case settles or goes to trial.
      </p>
      <div className="ms-md-4 mt-2 fw-light">
        <p>We help Tyler injury victims bring claims related to:</p>
        <ul className="mb-4 list">
          <li className="mb-2">
            <strong>Slip and Fall Accidents:</strong> Property owners have a duty to make their premises safe by repairing known dangers or
            warning others of such dangers. If a property owner breaches this duty, he or she may be considered negligent and may be held
            liable for foreseeable injuries caused by such negligence.
          </li>
          <li className="mb-2">
            <strong>Medical Malpractice:</strong> Our experienced attorneys are able to handle even the most complex medical malpractice
            claims. When medical professionals fail to perform according to the medical community’s accepted standards of care, we can hold
            doctors, nurses, and hospitals liable for the damages that result. Common medical errors include giving an incorrect diagnosis,
            prescribing the wrong medication, and performing a surgery incorrectly.
          </li>
          <li className="mb-2">
            <strong>Auto Accidents:</strong> If you have been hurt in an accident with a car, a motorcycle or a commercial vehicle, you have
            rights. We work to get you compensation for medical expenses, lost wages and your pain and suffering.
          </li>
        </ul>
      </div>
      <p className="fw-light">
        Attempting to negotiate with insurance companies or commercial defendants on you own puts you at a disadvantage. The attorneys at
        Negem & Worthington are resolute in the pursuit of compensation for our clients. We fight in settlement negotiations and a trial to
        get you the damage award that you deserve after an accident.
      </p>
    </div>
  ),
};

export default personalInjury;
