import React from "react";
import CallUs from "../components/CallUs";
import FooterNavLinks from "../components/FooterNavLInks";
import MainLogo from "../components/MainLogo";
import NewsLetterSignUp from "../components/NewsLetterSignUp";
import { topLinksList } from "../site-data/components/MenuNavLinks";
import FooterMobileNavLinks from "../components/FoorterMobileNavLInks";
import SocialMediaLinks from "../components/SocialMediaLInks";
import PageDataController from "../site-data/components/PageDataController";
import { Link } from "react-router-dom";

const Footer = () => {
  const practiceAreaLinks = new PageDataController().returnContentLinks("practiceAreas");
  const attorneyLinks = new PageDataController().returnContentLinks("attorneys");

  return (
    <div className="container-fluid  footer-container text-white">
      <div className="row">
        <div className="col-md-5  p-md-3 col-lg-4">
          <MainLogo />
          <div className="ms-3 mt-5 mb-4">
            <SocialMediaLinks />
          </div>
          <div className="ms-3 mt-4">
            <CallUs />
          </div>
          <div>
            <NewsLetterSignUp />
          </div>
          <div className="ms-2   mt-4 mb-4">
            <a href="https://www.martindale.com/">
              <img src="/img/MDH_Logo_Footer_White.png" alt="Martlindale-Hubbell website link" />
            </a>
          </div>
          <Link to="privacy-policy" className="fw-light ms-sm-3 ms-3 fs-6 nav-link nav-top-text text-white">
            Privacy Policy
          </Link>
        </div>

        <div className="col-md-7 col-lg-8 d-none d-lg-block">
          <div className="row">
            <div className="col-6">
              <FooterNavLinks headerLink={topLinksList.filter((el) => el.title === "Practice Areas")} bottomLinks={practiceAreaLinks} />
            </div>
            <div className="col-6">
              <FooterNavLinks headerLink={topLinksList.filter((el) => el.title === "Attorneys")} bottomLinks={attorneyLinks} />
              <FooterNavLinks
                headerLink={topLinksList.filter((el) => el.title === "Contact Us")}
                bottomLinks={topLinksList.filter((el) => el.title === "Map & Directions")}
              />
            </div>
          </div>
        </div>
        <div className="col-md-7 col-lg-8 d-block d-lg-none">
          <div className="row">
            <div className="col-12 d-flex flex-column justify-content-between h-100 ">
              <FooterMobileNavLinks
                headerLink={topLinksList.filter((el) => el.title === "Practice Areas")}
                bottomLinks={practiceAreaLinks}
              />
              <FooterMobileNavLinks headerLink={topLinksList.filter((el) => el.title === "Attorneys")} bottomLinks={attorneyLinks} />
              <FooterMobileNavLinks
                headerLink={topLinksList.filter((el) => el.title === "Contact Us")}
                bottomLinks={topLinksList.filter((el) => el.title === "Map & Directions")}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12  fw-lighter p-3 p-md-4" style={{ fontSize: ".6rem" }}>
          <p>
            Negem & Worthington, P.C. is located in Tyler, TX and serves clients in and around Tyler, Troup, Whitehouse, Arp, Bullard and
            Smith County.
          </p>
          <p>
            Attorney Advertising. This website is designed for general information only. The information presented at this site should not
            be construed to be formal legal advice nor the formation of a lawyer/client relationship.
          </p>
          <p>
            See our profile at
            <a className="ms-1" href="https://www.lawyers.com/tyler/texas/negem-and-worthington-1709947-f/" style={{ color: "white" }}>
              Lawyers.com
            </a>{" "}
            or
            <a className="ms-1 " href="https://www.martindale.com/" style={{ color: "white" }}>
              Martindale.com
            </a>
          </p>
          <p>
            Martindale-Hubbell and martindale.com are registered trademarks; AV, BV, AV Preeminent and BV Distinguished are registered
            certification marks; Lawyers.com and the Martindale-Hubbell Peer Review Rated Icon are service marks; and Martindale-Hubbell
            Peer Review Ratings are trademarks of MH Sub I, LLC, used under license. Other products and services may be trademarks or
            registered trademarks of their respective companies. Copyright © 2022 MH Sub I, LLC. All rights reserved.
          </p>
          <div className="d-flex justify-content-center">
              <div>
                <p style={{fontSize: "1rem"}}>© Copyright Negem and Worthington {new Date().getFullYear()} | Developed by <a href="https://etvsoftware.com/">ETVSoftware</a> in Tyler, Texas </p>
              </div>
          </div>
        </div>
      </div>
    </div>
     
  );
};

export default Footer;
