import React, { Fragment } from "react";
import Slider from "./Slider";
import FormContainer from "./ContactForms/FormContainer";
import InjuryTypes from "./InjuryTypes";

export const ContentPageContent = ({ data, form, scrollRefs }) => {
  // console.log(data);
  return (
    <div>
      <div className="container-xl bg-lightGrey" style={{ zIndex: "9999" }}>
        <div className="row" ref={(el) => (scrollRefs.current[0] = el)}>
          <div className="col-lg-7 col-xl-8 pt-5 p-md-5">
            {data.contentTitle ? <p className="fw-light h3 pb-3">{data.contentTitle}</p> : ""}
            {data.content}
          </div>
          <div className="col-lg-5 col-xl-4 order-first order-lg-last">
            <div className="sticky-top wm-25 mt-3 mb-3" style={{ top: "215px" }}>
              {data.showForm ? <FormContainer form={form} /> : <Fragment />}
            </div>
          </div>
        </div>
      </div>

      {/* Section - Injury types section */}
      {data.infoTiles ? (
        <div ref={(el) => (scrollRefs.current[1] = el)} className="pb-5">
          <InjuryTypes title={data.infoTitle} data={data.infoTiles} />
        </div>
      ) : (
        <Fragment />
      )}

      {/* Section - Accidents slider */}
      {data.showSlider ? (
        <div ref={(el) => (scrollRefs.current[2] = el)} className="mt-5">
          <h2 className="ms-5 me-5 fw-light">Our {data.title} Attorneys Can Also Assist With:</h2>
          <Slider />
        </div>
      ) : (
        <Fragment />
      )}
    </div>
  );
};

export default ContentPageContent;
