import React, { useState, useEffect } from "react";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import { Routes, Route } from "react-router-dom";

import Home from "./Pages/Home";
import SectionTitlePage from "./Pages/SectionTitlePage";
import SectionContentPage from "./Pages/SectionContentPage";
import SearchResults from "./Pages/SearchResults";
import SecondaryContent from "./Pages/SecondaryPage";
import FourOFour from "./Pages/FourOFour";
import VideoModal from "./components/videoModal";

const App = () => {
  // this code needs to be here to make the prerender react-snap work
  // this goes in all react applications for SEO reasons
  ///////////////////////////////////////////////////////////////////////

  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  if (!hasMounted) return null;

  const root = document.getElementById("root");
  if (root.hasChildNodes) root.innerHTML = "";
  //////////////////////////////////////////////////////////////////////

  return (
    <div className="w-100 position-relative">
      <VideoModal />
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/about" exact element={<SecondaryContent />} />
        <Route path="/client-process" exact element={<SecondaryContent />} />
        <Route path="/map-and-directions" exact element={<SecondaryContent />} />
        <Route path="/contact-us" exact element={<SecondaryContent />} />
        <Route path="/search-results" exact element={<SearchResults />} />
        <Route path="/videos/" exact element={<SectionContentPage />} />
        <Route path="/videos/:video" exact element={<SectionContentPage />} />
        <Route path="/practice-areas" exact element={<SectionTitlePage />} />
        <Route path="/practice-areas/:page" exact element={<SectionContentPage />} />
        <Route path="/attorneys" exact element={<SectionTitlePage />} />
        <Route path="/attorneys/:page" exact element={<SectionContentPage />} />
        <Route path="/privacy-policy" exact element={<SectionContentPage />} />
        <Route path="*" exact element={<FourOFour />} />
      </Routes>
      <Footer />
    </div>
  );
};

export default App;
