import link from "../links";

const jimmyNegemJr = {
  slug: "jimmy-negem-jr",
  link: "/attorneys/jimmy-negem-jr",
  image: "/img/2024/Jimmy_Center-bust.jpg",
  heroImage: "/img/2024/Jimmy_desk_web.jpg",
  imgAlt: "Jimmy Negem Jr in a nice suit with a smile",
  imageYAlign: "0",
  customImageAlignment: " center center ",
  title: "Jimmy M. Negem Jr.",
  form: "DiscussYourCase",
  showSlider: true,
  showProcess: true,
  showForm: true,
  description:
    " Born and raised in Tyler, Texas, Jimmy Negem, Jr. has been an attorney with Negem & Worthington since 2019. Jimmy specializes in personal injury law, including car accidents, 18-wheeler accidents, and workplace injuries ...",
  content: [
    <div className="row overview mb-5">
      <div className="h2 fw-light mb-4">Meet Jimmy</div>
      <p className="fw-light mt-2">
        Born and raised in Tyler, Texas, Jimmy Negem, Jr. has been an attorney with Negem & Worthington since 2019. Jimmy specializes in
        personal injury law, including car accidents, 18-wheeler accidents, and workplace injuries. In addition, Jimmy also has experience
        in other areas of practice, including probate disputes, real estate, and many others.
      </p>
      <p className="fw-light mt-2">
        Prior to joining the firm, Jimmy received his undergraduate degree from {link("Baylor University")}, where he was a member of Kappa
        Omega Tau Fraternity. He graduated from Baylor with a Bachelor’s in Business Administration in 2016 before earning his Doctorate of
        Jurisprudence from {link("Baylor Law School")} in 2019. While in law school, Jimmy competed in mock trial competitions both within{" "}
        {link("Baylor Law School")} and nationally, representing {link("Baylor Law School")} and competing against other top law schools
        across the country.
      </p>
      <p className="fw-light mt-2">
        Since becoming an attorney, Jimmy has been recognized as a Top 40 Under 40 personal injury attorney in the state of Texas in both
        2020 and 2021 by the {link("American Institute of Trial Lawyers")} as well as the {link("American Academy of Attorneys")}. In
        addition to these organizations, Jimmy is also an active member of the {link("Texas Trial Lawyers Association")} and the{" "}
        {link("State Bar of Texas")}.
      </p>
      <p className="fw-light mt-2">
        When not working, Jimmy enjoys spending time with his friends and family, especially his beautiful daughter, Annakate. He is also a
        member of the {link("Baylor Bear Foundation")} and is an avid supporter of Baylor Athletics.
      </p>
    </div>,

    <div className="row experience mb-5">
      <div className="h3 fw-light mb-2">Education</div>
      <div className="ms-md-4 mt-2 fw-light">
        <ul className="mb-4 list">
          <li>{link("Baylor Law School")}, Doctorate of Jurisprudence, (2019)</li>
          <li>{link("Baylor University")}, Bachelors of Business Administration, (2016)</li>
        </ul>
      </div>
    </div>,

    <div className="row admissions mb-5">
      <div className="h3 fw-light mb-2">Admissions</div>
      <div className="ms-md-4 mt-2 fw-light">
        <ul className="mb-4 list">
          <li>{link("State Bar of Texas")}</li>
        </ul>
      </div>
    </div>,

    <div className="row professional mb-5">
      <div className="h3 fw-light mb-2">Professional Affiliations</div>
      <div className="ms-md-4 mt-2 fw-light">
        <ul className="mb-4 list">
          <li>{link("State Bar of Texas")}</li>
          <li>{link("American Academy of Attorneys")}</li>
          <li>{link("American Institute of Trial Lawyers")}</li>
          <li>{link("Texas Trial Lawyers Association")}</li>
        </ul>
      </div>
    </div>,
  ],
};

export default jimmyNegemJr;
