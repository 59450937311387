import React, { useEffect, useState, useRef } from "react";

import { useLocation } from "react-router-dom";
import YouTubeService from "../helpersScripts/YouTubeService";
import { connect } from "react-redux";
import { fetchVideos } from "../../actions";
import FormContainer from "./ContactForms/FormContainer";
import VideoTiles from "./VideoTiles";
import Pagination from "./Pagination";
import Video from "./Video";

/**
 * Renders the Videos component with pagination, a form, and a list of video tiles.
 *
 * @param {Object} props - The properties object.
 * @param {Object} props.form - The form data used in the component.
 * @param {Array} props.videos - The list of videos to be displayed.
 * @*/
const Videos = ({ form, videos, fetchVideos, selectedVideo }) => {
  const location = useLocation();
  const path = location.pathname.split("/");
  const slug = path.pop();

  const youTubeService = new YouTubeService();
  let [currentStartVideo, setCurrentStartVideo] = useState(0);
  let [visibleVideos, setVisibleVideos] = useState();
  let [firstVisit, setFirstVisit] = useState(true);
  let [topVideoPosition, setTopVideoPosition] = useState(0);
  let [previousLocation, setPreviousLocation] = useState(null);
  let [currentVideoList, setCurrentVideoList] = useState([]);
  let [currentSelectedVideo, setCurrentSelectedVideo] = useState(null);

  const videoPerPage = 5;

  const prevLocation = useRef(null);

  useEffect(() => {
    prevLocation.current = location.pathname;
    setPreviousLocation(location.pathname);

  },[location]);

  useEffect(()=> {
    configureVisibleVideos();
  }, [currentVideoList]);

  useEffect(() => {
    getVideos().then(async () => {
      await fetchVideos(currentVideoList);
      configureVisibleVideos(currentVideoList);
    });
  }, [currentStartVideo, fetchVideos]);

  useEffect(() => {

    if(slug !== "videos") {
      setTimeout(()=>{

        scrollToElement(document.getElementById("singleVideoContainer"), true);

      }, 75)
    }
  }, [slug])


  const firstElement = (current) => {

    setCurrentStartVideo(current);
    setTimeout(() => {

      if (firstVisit || currentSelectedVideo !== null) {
        setTopVideoPosition(document.getElementById(currentSelectedVideo ? currentSelectedVideo.id :"visible__videos").getBoundingClientRect().top)
      }
      scrollToElement(document.getElementById("visible__videos"));

      const headerOffset = 200;
      const offsetPosition = topVideoPosition - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      })
      setFirstVisit(false);
    }, 100);
    const currentUrl = document.referrer;

  };

  const scrollToElement = (element, instant = false) => {

    if (element === undefined) return;

    const videoPosition = element.getBoundingClientRect().top;
    const headerOffset = 200;
    const offsetPosition = videoPosition - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: instant ? "instant" : "smooth",
    })
  }

  const getVideos = async () => {

    if (currentVideoList.length === 0) {

      const response = await youTubeService.requestPlayList(0);
      const data = await response.data;

      const videos = data.items.map((video, i) => {
        return {
          id: video.id,
          publishedAt: new Date(video.snippet.publishedAt),
          title: video.snippet.title,
          description: video.snippet.description,
          image: video.snippet.thumbnails.high.url,
          link: `/videos/${video.snippet.resourceId.videoId}`,
        };
      }).sort((a, b) => b.publishedAt - a.publishedAt);
      setCurrentVideoList(videos);
  }

  };



  const configureVisibleVideos = () => {

    setVisibleVideos(
      Array.from(currentVideoList).filter((el, i) => {
        if (i >= currentStartVideo && i < currentStartVideo + videoPerPage) {
          return el;
        }
        return null;
      })
    );
  };







    return (
      <React.Fragment>
        {/*<Video />*/}
        <div className="container-xl bg-lightGrey" style={{ zIndex: "9999" }}>
          <div className="row">
            <div className="col-lg-7 col-xl-8 px-md-5" id="visible__videos">
              <VideoTiles tiles={visibleVideos}  />

              <Pagination pageFn={firstElement} itemsPerPage={videoPerPage} itemCount={currentVideoList.length} />
            </div>

            <div className="col-lg-5 col-xl-4 order-first order-lg-last">
              <div className="sticky-top wm-25 mt-3 mb-3" style={{ top: "215px" }}>
                <FormContainer form={form} />
              </div>
            </div>
          </div>
        </div>

      </React.Fragment>
    );

};

const mapStateToProps = (state) => {
  return {
    videos: state.videos,
    selectedVideo: state.selectedVideo,
  };
};

export default connect(mapStateToProps, { fetchVideos })(Videos);
