import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import {useLocation} from "react-router-dom";
import { fetchModalIsOpen } from "../../actions";



const VideoModal = ({modalOpen, selectedVideo, fetchModalIsOpen }) => {
    const location = useLocation();
    const ref = useRef(null);
    // let path = location.pathname.split("/");

    let [url, setUrl] = useState(" ");
    // const slug = path.pop();

    const [windowWidth, setWindowWidth] = useState(0);
    const [videoWidth, setVideoWidth] = useState(0);
    const [videoHeight, setVideoHeight] = useState(0);
    const [path, setPath] = useState("");

    const [style, setStyle] = useState({ top:"10px", right: "2%"});
    const [classes, setClasses] = useState("position-absolute")
    const [ratio, setRatio] = useState(0);

    useEffect(() => {
        if (modalOpen && selectedVideo !== undefined) {

            setPath(selectedVideo.split("/").pop());
            const body = document.querySelector("body");
            body.classList.add("modal-open");

            setUrl(`https://www.youtube.com/embed/${selectedVideo.split("/").pop()}`);
        } else {
            const body = document.querySelector("body");
            body.classList.remove("modal-open");
        }
    }, [modalOpen])

    const closeModal = () => {
        fetchModalIsOpen(!modalOpen)
    }

    let resizeWindow = () => {

        const width = window.innerWidth;
        const height = window.innerHeight;
        setRatio(height / width);

        if (ratio > 1.0) {
            setWindowWidth(width * .95);

        } else if (ratio > .52 && ratio < 1.0) {

            setWindowWidth(width * .80);
        } else {
            setWindowWidth(height * .95 * 1.77777);
        }

        setVideoWidth((windowWidth) | 0);
        setVideoHeight((windowWidth * 0.5625) | 0);


    };



    useEffect(() => {
        resizeWindow();
        window.addEventListener("resize", resizeWindow);
        return () => window.removeEventListener("resize", resizeWindow);
    });

  return (
      <React.Fragment>

          <div id="modal" className={`position-fixed  top-0 left-0 w-100 h-100 ${modalOpen ? "show": ""}`}  style={{backgroundColor:"rgba(0,0,0,0.8)", display: `${modalOpen? "unset":"none"}`, transition: "background-color 1s ease", zIndex: 2000}}>
              <div className="container p-md-5 pb-5  d-flex flex-column justify-content-center h-100 align-items-center" >
                  <div className="position-relative justify-content-end m-5" style={{height:videoHeight, width:videoWidth}}>

                      <div style={ratio > .55 ? {top: -50, right:0}: {top: 0, right: -50} } className={"video-pane"} >

                        <button className="btn btn-outline-primary mb-2 float-right" onClick={closeModal} ><i>X</i></button>
                      </div>
                      <div className="d-flex justify-content-center ">
                          { modalOpen ?
                            <iframe ref={ref} src={`https://www.youtube.com/embed/${selectedVideo.split("/").pop()}`}  title={selectedVideo.split("/").pop()} height={videoHeight} width={videoWidth} allowFullScreen></iframe>

                          :""}
                      </div>
                  </div>
              </div>
          </div>

      </React.Fragment>
  ) ;
};

const mapStateToProps = (state) => {
    return {
        selectedVideo: state.selectedVideo,
        modalOpen: state.modalOpen,
    };
};

export default connect(mapStateToProps, {fetchModalIsOpen } )(VideoModal);