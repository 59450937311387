import { ADDRESS1, ADDRESS_SUITE, ADDRESS_CITY, ADDRESS_STATE, ADDRESS_ZIP, COMPANY_PHONE, COMPANY_FAX } from "../../../company-info";

export const directions = {
  slug: "map-and-directions",
  image: "/img/contactus.jpg",
  imageYAlign: 0,
  imgAlt: "Individual holding a phone",
  title: "Map and Directions",
  description: "Find us ...",
  link: "/map-and-directions",
  form: "DiscussYourCase",
  formTopPosition: true,
  showForm: false,
  contentTitle: "",

  content: [
    <div className="p-4">
      <div className="row ms-3 mt-5 ">
        <div className="bg-white px-4 pt-3 pb-2 col-auto">
          <h4 className="fw-light mt-4 mb-3 text-primary ps-3">Our Tyler Office</h4>
          <p className="px-3 pb-3 lh-lg">
            {ADDRESS1} {ADDRESS_SUITE}
            <br />
            {ADDRESS_CITY}, {ADDRESS_STATE} {ADDRESS_ZIP}
            <br />
            <strong>Phone:</strong> {COMPANY_PHONE}
            <br />
            <strong>Fax:</strong> {COMPANY_FAX}
          </p>
        </div>
      </div>
    </div>,
    <div>
      <p className="fw-light mb-2 mt-2">If you would like to get directions on how to find us, please enter your address below.</p>
      <p className="fw-light mb-5">
        To get directions to this location, click "Directions" or "View larger map" on the map below (Google Maps will openin a new window).
      </p>
    </div>,
    <div>
      <iframe
        style={{ height: "550px", width: "100%" }}
        src="https://www.google.com/maps/embed/v1/place?key=AIzaSyD1R-01cFPivuWDZL_RhRVHrkGrJVH9rgg&amp;zoom=16&amp;maptype=roadmap&amp;q=1828 ESE Loop 323 Suite R-1A ,&nbsp;Tyler, Texas , 75701"
        allowFullScreen=""
        loading="lazy"
        frameBorder="0"
        title="map"
      />
    </div>,
  ],
};
