export const videos = {
  slug: "videos",
  image: "/img/courtroom.jpg",
  titlePosition: "center",
  imageYAlign: 0,
  imgAlt: "courtroom",
  title: "Videos",
  description: "A page containing all of our videos",
  link: "/videos",
  form: "DiscussYourCase",
  formTopPosition: true,

  content: [],
};
