import link from "../links";

const nickNegem = {
  slug: "nick-negem",
  link: "/attorneys/nick-negem",
  image: "/img/2024/Nick_Center-Bust.jpg",
  heroImage: "/img/2024/Nick_web_desk.jpg",
  imgAlt: "Nick Negem in a nice suit with a smile",
  imageYAlign: "30",
  title: "Nick Negem",
  form: "DiscussYourCase",
  showSlider: true,
  showProcess: true,
  showForm: true,
  description:
    " Born and raised in Tyler, Texas, Nicholas Negem has worked for Negem and Worthington in various capacities since 2014 and has been serving as an attorney with the firm since 2022. Nick specializes in personal injury law, including car accidents ...",
  content: [
    <div className="row overview mb-5">
      <div className="h2 fw-light mb-4">Meet Nick</div>
      <p className="fw-light mt-2">
        Born and raised in Tyler, Texas, Nicholas Negem has worked for Negem and Worthington in various capacities since 2014 and has been
        serving as an attorney with the firm since 2022. Nick specializes in personal injury law, including car accidents, 18-wheeler
        accidents, and workplace injuries.
      </p>
      <p className="fw-light mt-2">
        Before joining Negem and Worthington full-time, Nick received his undergraduate degree from Baylor University, where he was a member
        of Kappa Omega Tau Fraternity. He graduated from Baylor with a Bachelor’s in Business Administration in 2018 before earning his
        Doctorate of Jurisprudence from Baylor Law School in 2020. He is honored to continue the Negem legacy and passionately represents
        his clients.
      </p>
      <p className="fw-light mt-2">
        When not practicing law, Nick enjoys traveling, playing golf, volunteering and spending time with family.
      </p>
    </div>,

    <div className="row experience mb-5">
      <div className="h3 fw-light mb-2">Education</div>
      <div className="ms-md-4 mt-2 fw-light">
        <ul className="mb-4 list">
          <li>{link("Baylor Law School")}, Doctorate of Jurisprudence, (2020)</li>
          <li>{link("Baylor University")}, Bachelors of Business Administration, (2018)</li>
        </ul>
      </div>
    </div>,

    <div className="row admissions mb-5">
      <div className="h3 fw-light mb-2">Admissions</div>
      <div className="ms-md-4 mt-2 fw-light">
        <ul className="mb-4 list">
          <li>{link("State Bar of Texas")}</li>
        </ul>
      </div>
    </div>,

    <div className="row professional mb-5">
      <div className="h3 fw-light mb-2">Professional Affiliations</div>
      <div className="ms-md-4 mt-2 fw-light">
        <ul className="mb-4 list">
          <li>{link("State Bar of Texas")}</li>
        </ul>
      </div>
    </div>,
  ],
};

export default nickNegem;
