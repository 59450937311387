import React, { useRef } from "react";
import paralysisTile from "../../injuryLitigationTypes/tiles/paralysis";
import spinalInjuriesTile from "../../injuryLitigationTypes/tiles/spinalInjuries";
import wrongfulDeathTile from "../../injuryLitigationTypes/tiles/wrongfulDeath";

const wrongfulDeath = {
  slug: "wrongful-death",
  link: "/practice-areas/wrongful-death",
  image: "/img/PASlider/wrongfulDeath.jpg",
  imageYAlign: "80",
  imgAlt: "lonely chair and table in archway",
  title: "Wrongful Death",
  description:
    "Loosing anyone due to death caused by a collision or medical malpractice is hearbreaking. Contacting an attorney is crucial to the success of a case. ",
  contentTitle: "",
  infoTitle: "Injury Types",
  infoTiles: [paralysisTile, spinalInjuriesTile, wrongfulDeathTile],
  showSlider: true,
  showProcess: true,
  showForm: true,
  // this is the content of the page in JSX
  content: (
    <div className="row overview ">
      <div className="h2 fw-light mb-4 ">Wrongful Death Law Firm</div>
      <div className="h4 fw-light mb-4 mt-2">Best Wrongful Death Lawyers in East Texas</div>
      <p className="fw-light mt-2">
        Don’t be left empty handed with the devastation of losing a family member due to negligence of another party. Negem & Worthington
        can help you take legal action. Life can be turned upside down instantaneously leaving you trying to cope with the sorrow and grief
        after losing a loved one. Many times, this sudden loss leaves family members to account for their loved ones medical costs, funeral
        expenses, and lost income and/or health care benefits. Texas’ Wrongful Death Statute allows surviving family members or guardians to
        bring action against the party whose negligence or wrongful act caused the death of their loved one. The wrongful death suit
        provides relief to the family members who have been injured emotionally and financially as a result of their loss.
      </p>
      <p className="fw-light">
        The attorneys at Negem & Worthington strive to let families focus on their emotional recovery while we handle all aspects of the
        wrongful death case, from investigating the accident to easing the stress of negotiations and trial.
      </p>

      <h4 className="fw-light mt-5 "> What Should I Do in the Event of the Wrongful Death of a Loved One?</h4>

      <p className="fw-light mt-3">
        Don’t feel like you cannot get compensation for the wrongful death of a loved one. In Texas, recovery is meant to compensate the
        family members of the deceased for the incurred damages because of the wrongful death, which may be both economic and non-economic
        in nature. Damages may include reimbursement or compensation for:
      </p>
      <div className="ms-md-5 mt-2 fw-light">
        <p>Economic Damages can include:</p>
        <ul className="mb-4 list">
          <li>Funeral and burial expenses</li>
          <li>Loss of future earnings the deceased would have provided for the surviving family members</li>
          <li>Lost pensions, retirement, gifts, and other benefits</li>
          <li>Reasonable value of household services</li>
        </ul>
        <p>Non-economic Damages can include:</p>
        <ul>
          <li>Loss of companionship, love, comfort, care, protection, and moral support</li>
          <li>Loss of consortium</li>
          <li>Loss of guidance for children</li>
          <li>Mental anguish and suffering</li>
        </ul>
      </div>
      <p className="fw-light">
        Unfortunately, a wrongful death case cannot bring back a loved one that was lost but a wrongful death lawsuit that is successful can
        provide you and your family with some security in the unforeseen future.
      </p>
    </div>
  ),
};

export default wrongfulDeath;
