import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import { fetchSearchMenuState } from "../../actions";

const SearchIcon = ({ menuIsOpen, fetchSearchMenuState }) => {
  const ref = useRef();

  const handleClick = (event) => {
    // const icon = document.querySelector(".nav-search-container");

    if (ref.current.contains(event.target)) {
      fetchSearchMenuState(!menuIsOpen);
    }
  };

  useEffect(() => {
    document.body.addEventListener("click", handleClick, {
      capture: true,
    });

    return () => {
      document.body.removeEventListener("click", handleClick, { capture: true });
    };
  });

  return (
    <React.Fragment>
      <div ref={ref} className="nav-search-container">
        <img className="nav-search-icon" src="/img/search.svg" alt="search" />
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    menuIsOpen: state.searchOpen,
  };
};

export default connect(mapStateToProps, { fetchSearchMenuState })(SearchIcon);
