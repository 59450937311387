import React from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

const SliderCard = ({ card }) => {
  const location = useLocation();
  const path = location.pathname.split("/");
  const slug = path.pop();

  return (
    <Link to={card.link} style={{ textDecoration: "none" }}>
      <div
        className={`tile m-3 shadow-sm ${slug === card.link.split("/").pop() ? "opacity-25" : ""}`}
        style={{ background: `url('${card.image}') center center/cover`, height: "360px" }}
      >
        <div
          className="position-realtive "
          style={{
            background: "linear-gradient(0deg, rgba(0,0,0,0.49625787815126055) 20%, rgba(0,0,0,0) 63%)",
            height: "360px",
          }}
        ></div>
        <div className="pe-3 ps-3 pb-1 text-white position-relative fw-normal" style={{ transform: "translateY(-100%)" }}>
          <h4 className={`fw-light ${slug === card.link.split("/").pop() ? "text-primary " : "text-white"}`}>{card.title}</h4>
          <div className="tile-line"></div>
          <p className="fw-light mt-2">{card.description}</p>
        </div>
      </div>
    </Link>
  );
};

export default SliderCard;
