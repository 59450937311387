export const about = {
  slug: "about",
  image: "/img/NW-group.jpg",
  imageYAlign: 35,
  imgAlt: "Negem and Worthington standing for a picture",
  title: "About Us",
  link: "/about",
  form: "DiscussYourCase",
  formTopPosition: false,
  showForm: true,
  description:
    "At Negem & Worthington Law Office, our clients and their families are our only priority. We listen intently. We care immensely...",
  contentTitle: "",
  content: [
    "At Negem & Worthington Law Office, our clients and their families are our only priority. We listen intently. We care immensely. We help injury and accident victims needing legal services move forward with their claims.",
    "Our East Texas Injury attorneys work hard to build strong client relationships while providing a positive experience for each person we help.",
    "We put our almost three decades of legal skills to work helping accident victims and families in our hometown of Tyler and throughout Texas. Let a Negem & Worthington Law Office attorneys in Tyler, Texas help you now!",
  ],
};
