import React from "react";
import { Link } from "react-router-dom";
import PageDataController from "../site-data/components/PageDataController";
import { COMPANY_PHONE } from "../site-data/company-info";

const FourOFour = () => {
  const [data] = new PageDataController("page-not-found").returnTitlePage();

  document.title = "N&W | 404";

  return (
    <div className="bg-lightGrey" id="overview">
      <div className="" style={{ background: `url(${data.image})  40% ${data.imageYAlign}% /cover` }}>
        <div>
          <div
            className="container-md text-white text-center ps-4 ps-xl-5 ps-xxl-0 heroText section-content-header"
            style={{
              paddingBottom: "1rem",
              fontFamily: "PT Serif Caption,serif",
              zIndex: "1",
              textShadow: "3px 3px 12px rgba(0,0,0,0.3)",
            }}
          >
            {data.title}
          </div>
        </div>
      </div>
      <div className="container-xl bg-lightGrey pb-lg-5" style={{ zIndex: "9999" }}>
        <div className="row d-flex justify-content-center" style={{ minHeight: "400px" }}>
          <div className="col-lg-7 col-xl-8 pt-5 p-sm-5 px-4 ">
            <p className="fw-light">
              Start with one of the links below to find what you are looking for. If that does not help, feel fee to call us at{" "}
              {COMPANY_PHONE}
            </p>
            <div className="d-flex flex-column text-center mt-5 bg-white p-5">
              <Link to="/map-and-directions" className="text-primary mb-3">
                Map & Directions
              </Link>
              <Link to="/practice-areas" className="text-primary mb-3">
                Practice Areas
              </Link>
              <Link to="/attorneys" className="text-primary mb-3">
                Attorneys
              </Link>
              <Link to="/contact-us" className="text-primary">
                Contact us for a free consultation
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FourOFour;
