import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { fetchMenuIsOpen } from "../../actions";

const MenuIcon = (props) => {
  //   const [menuIsOpen, setMenuOpen] = useState(true);
  const [animate, setAnimate] = useState(" ");
  const ref = useRef();

  const handleClick = (event) => {
    if (ref.current.contains(event.target)) {
      props.fetchMenuIsOpen(!props.menuOpen);

      return;
    }
  };

  useEffect(() => {
    setAnimate(`${props.menuOpen ? "open" : "close"}`);
  }, [props.menuOpen]);

  useEffect(() => {
    document.body.addEventListener("click", handleClick, {
      capture: true,
    });

    return () => {
      document.body.removeEventListener("click", handleClick, {
        capture: true,
      });
    };
  });

  return (
    <div ref={ref} className="burger-container">
      <div>
        <span className={`burger top ${animate}`}></span>
        <span className={`burger middle ${animate}`}></span>
        <span className={`burger bottom ${animate}`}></span>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return { menuOpen: state.menuOpen };
};

export default connect(mapStateToProps, { fetchMenuIsOpen })(MenuIcon);
